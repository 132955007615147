import React from 'react'
import { Link } from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'
import Icon4 from '../../assets/images/icons/icon4.png'

const OurSolutions = () => {
    return (
        <section className="solutions-area pb-70">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="icon" /> 
                        Empresas
                    </span>
                    <h2>Industrias en las que nos enfocamos</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                            <img src={Icon4} alt="Icon4" />
                            
                            </div>
                            <h3>|
                                <Link to="/service-details-two">
                                    Startup ApplicationsS
                                </Link>
                            </h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna adipiscing aliqua.</p>

                           {/*  <Link to="/service-details-two" className="view-details-btn">
                            Ver detalles
                            </Link> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                            <img src={Icon4} alt="Icon4" />
                            </div>
                            <h3>
                                <Link to="/service-details-two">
                                    SaaS Solutions
                                </Link>
                            </h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna adipiscing aliqua.</p>
                            
                           {/*  <Link to="/service-details-two" className="view-details-btn">
                            Ver detalles
                            </Link> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                        <div className="single-solutions-box">
                            <div className="icon">
                            <img src={Icon4} alt="Icon4" />
                           
                            </div>
                            <h3>
                                <Link to="/service-details-two">
                                    E-Commerce Platforms
                                </Link>
                            </h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna adipiscing aliqua.</p>
                            
                           {/*  <Link to="/service-details-two" className="view-details-btn">
                            Ver detalles
                            </Link> */}

                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
    )
}

export default OurSolutions